<div class="project-builder">
  <form class="project-builder__content">
    <div class="project-builder__step">
      <div class="modal-header">
        <div class="text-container">
          <h2>{{ "__createProject" | translate }}</h2>
        </div>

        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="cancel(true)"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="form-group-wrapper">
        <h2 class="form-group-wrapper__label">{{ "__general" | translate }}</h2>
        <p>{{ "__generalText" | translate }}</p>

        <div class="create-project-row">
          <div class="data-block">
            <div>
              <label for="name">{{ "__name" | translate }}</label>
              <input
                class="field data-input"
                type="text"
                placeholder="{{ '__name' | translate }}"
                name="name"
                [(ngModel)]="project.name"
              />
            </div>

            <div class="second-row">
              <label for="status">{{ "__projectStatus" | translate }}</label>
              <select
                class="custom-select select-style"
                placeholder="Choose"
                [(ngModel)]="project.status"
                name="status"
              >
                <option *ngFor="let status of statuses" [value]="status.id">
                  {{ status.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="data-block">
            <div>
              <label for="language">{{ "__language" | translate }}</label>
              <select
                class="custom-select select-style data-input"
                placeholder="Choose"
                [(ngModel)]="project.language"
                name="language"
              >
                <option *ngFor="let l of languages" [value]="l.value">
                  {{ l.title | translate }}
                </option>
              </select>
            </div>

            <div class="data-block-slider-toggle second-row">
              <label class="title-toggle" for="auto_register_enabled">{{
                "__autoregister" | translate
              }}</label>
              <mat-slide-toggle
                class="slide-toggle-style"
                [(ngModel)]="project.auto_register_enabled"
                name="auto_register_enabled"
              >
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <h2 class="form-group-wrapper__label">
          {{ "__instruments" | translate }}
        </h2>
        <p>{{ "__instrumentsText" | translate }}</p>
        <app-instrument-cards
          [instruments]="clientInstruments"
          [allowMultipleChoice]="false"
        ></app-instrument-cards>

        <h2 class="form-group-wrapper__label">
          {{ "__communications" | translate }}
        </h2>
        <p>{{ "__communicationsText" | translate }}</p>

        <div class="create-project-row">
          <div class="data-block-second-section">
            <div>
              <label for="process_name">{{
                "__processName" | translate
              }}</label>
              <input
                class="field"
                type="text"
                placeholder="{{ '__processName' | translate }}"
                name="process"
                [(ngModel)]="project.process_name"
              />
            </div>
            <div>
              <label for="time">{{ "__time" | translate }}</label>
              <input
                class="field"
                type="number"
                min="0"
                placeholder="{{ '__time' | translate }}"
                name="time"
                [(ngModel)]="project.time"
              />
            </div>
          </div>

          <section class="client-info data-block-second-section">
            <label for="image">{{ "__projectImage" | translate }}</label>

            <div class="client-info__content">
              <div class="specific-info">
                <div class="specific-info__row">
                  <div class="specific-info__col">
                    <app-upload-image
                      [(imgUrl)]="project.image"
                      [title]="'__logoType' | translate"
                    ></app-upload-image>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="data-block-second-section">
            <div class="data-block-slider-toggle">
              <label class="title-toggle" for="feedback">{{
                "__sendFeedback" | translate
              }}</label>
              <mat-slide-toggle
                class=""
                placeholder="Choose"
                [(ngModel)]="project.send_feedback"
                name="feedback"
              >
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
      <div class="project-builder__step__buttons">
        <button type="button" class="btn btn-naked" (click)="cancel(true)">
          {{ "__cancel" | translate }}
        </button>
        <button
          type="submit"
          class="btn btn-main"
          [disabled]="!projectIsValid()"
          (click)="createProject()"
        >
          {{ "__createProject" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
