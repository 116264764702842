<div class="upload-image__container">
  <div class="upload-image__img-actions">
    <div class="loader custom-loader-size" *ngIf="loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <div *ngIf="imgUrl && !loading" class="upload-image__preview">
      <img [src]="getImgUrl()" />
    </div>
    <div
      *ngIf="!imgUrl && !loading"
      (click)="imgInput.click()"
      class="upload-image__preview img-placeholder-wrapper"
    >
      <i class="material-icons img-placeholder-icon">add_photo_alternate</i>
    </div>
    <div class="upload-image__upload">
      <button (click)="imgInput.click()" class="btn-basic">
        {{ "__uploadImg" | translate }}
      </button>
      <button *ngIf="canRemoveImg()" (click)="removeImg()" class="btn-delete">
        {{ "__rmImg" | translate }}
      </button>
    </div>
    <p class="upload-image__title" [innerHTML]="title"></p>
  </div>
  <div class="upload-image__tip">
    <input
      hidden
      #imgInput
      class="field"
      type="file"
      name="logo"
      accept="image/*"
      (change)="uploadImage($event.target)"
    />
    <!--<p [innerHTML]="comments"></p>-->
  </div>
</div>
