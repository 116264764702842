import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareService {


  breadcrumb: string;
  constructor() { }

  setBreadcrumb(breadcrumb) {
    this.breadcrumb = breadcrumb;
    this.emitChangeSource.next(true);
  }
  getBreadcrumb() {
    return this.breadcrumb;
  }

  // Observable string sources
  private emitChangeSource = new Subject<any>();
  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();
  // Service message commands
  emitChange(breadcrumbShow) {
    this.emitChangeSource.next(breadcrumbShow);
  }

  // Observable string sources
  private emitChangeSource_report = new Subject<any>();
  // Observable string streams
  changeEmitted_report$ = this.emitChangeSource_report.asObservable();
  // Service message commands
  emitChange_report(moveProject) {
    this.emitChangeSource_report.next(moveProject);
  }

}
