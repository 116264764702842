import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Settings } from '../../classes/settings';
import { GroupService } from '../../services/group.service';
import { AlertsService } from '../../services/alerts.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-upload-image',
  standalone: true,
  imports: [ TranslateModule, CommonModule ],
  templateUrl: './upload-image.component.html',
  styleUrls: [ './upload-image.component.scss' ]
})
export class UploadImageComponent implements OnInit {

  @Input() imgUrl: string;
  @Output() imgUrlChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() defaultImgUrl: string;

  @Input() title: string = '';
  @Input() comments: string = '';
  @Input() imgIsPrivate: boolean = false;
  @Input() allowRemoveImg: boolean = true;

  loading: boolean = false;

  constructor(
    private groupService: GroupService,
    private alertService: AlertsService,
  ) { }

  ngOnInit() {
    if (!this.imgUrl && this.defaultImgUrl) {
      this.imgUrl = this.defaultImgUrl;
    }
  }

  canRemoveImg() {
    return this.imgUrl && this.allowRemoveImg && this.defaultImgUrl !== this.imgUrl;
  }

  removeImg() {
    this.imgUrl = this.defaultImgUrl ? this.defaultImgUrl : '';
    this.emitImg();
  }

  uploadImage(event: EventTarget) {
    let img = (event as HTMLInputElement).files[ 0 ];
    this.loading = true;
    const formData = new FormData();
    formData.append('file', img);
    this.groupService.submitFile(formData).subscribe(
      response => {
        this.imgUrl = this.imgIsPrivate ? response.uuid : response.url,
          this.emitImg();
        this.loading = false;
      },
      error => {
        this.alertService.setAlert(this.alertService.formatErrorMessage(error));
        this.loading = false;
      }
    );
  }

  emitImg() {
    this.imgUrlChange.emit(this.imgUrl);
  }

  getImgUrl() {
    const jwtToken = localStorage.getItem('jwt');
    return this.imgUrl.indexOf('http') === -1 && this.imgIsPrivate ? `${Settings.UPLOAD_FILE_URL}${this.imgUrl}/?jwt=${jwtToken}` : this.imgUrl;
  }

}
