
export class Project {

    static STATUSES = [
        { id: 'ACT', name: 'Active' },
        { id: 'DIS', name: 'Disabled' },
        { id: 'EXP', name: 'Expired' },
        { id: 'PAU', name: 'Pause' },
    ];

    static STATUS_DICT = {
        ACT: 'Active',
        DIS: 'Disabled',
        EXP: 'Expired',
        PAU: 'Pause'
    };

    static AUTO_REGISTER = [
        { id: false, name: 'No' },
        { id: true, name: 'Yes' },
    ];

    static FEEDBACK_OPTIONS = [
        { id: false, name: 'No' },
        { id: true, name: 'Yes' },
    ];

    id: number;
    name: string;
    status: string;
    register_token: string;
    autoregister_url: string;
    auto_register_enabled: boolean = false;
    client: number;
    send_feedback: boolean = false;
    language: string = 'en';
    instruments: any[] = [];
    candidates: any = [];
    image: any;
    process_name: string;
    time: number;
    uuid: any;
}

export class ProjectAnalytics {

    candidates_by_status: {
        total: number,
        invited: number,
        in_progress: number,
        evaluated: number,
    };
    reminded_candidates: number;

}
