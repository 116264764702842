import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Project } from '../../classes/project';
import { InstrumentService } from '../../services/instrument.service';
import { Instrument } from '../../classes/instrument';
import { ProjectService } from '../../services/project.service';
import { InstrumentCardsComponent } from '../instrument-cards/instrument-cards.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UploadImageComponent } from '../upload-image/upload-image.component';
import { Client } from '../../classes/client';
import { AlertsService } from '../../services/alerts.service';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


@Component({
  selector: 'app-create-project',
  standalone: true,
  imports: [ CommonModule, TranslateModule, UploadImageComponent, FormsModule, InstrumentCardsComponent, MatSlideToggleModule ],
  templateUrl: './create-project.component.html',
  styleUrls: [ './create-project.component.scss' ]
})
export class CreateProjectComponent implements OnInit {

  @Output() created = new EventEmitter<boolean>();
  @Output() close = new EventEmitter<boolean>();
  @Output() refresh = new EventEmitter<boolean>();

  @ViewChild(InstrumentCardsComponent) instrumentCardsComponent: InstrumentCardsComponent;

  project: Project = new Project;
  statuses = Project.STATUSES;
  feedback = Project.FEEDBACK_OPTIONS;
  auto_register_enabled = Project.AUTO_REGISTER;
  clientInstruments: Instrument[] = [];
  languages = ProjectService.LANGUAGES;

  @Input() client: Client;

  constructor(
    private instrumentService: InstrumentService,
    private projectService: ProjectService,
    private alertsService: AlertsService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {

    this.project.client = this.client.id;
    this.setClientInstruments();

  }

  setClientInstruments() {
    this.instrumentService.getInstrumentsByClient(this.project.client.toString()).pipe().subscribe(
      response => this.clientInstruments = response
    );
  }

  createProject() {
    this.project.instruments = this.instrumentCardsComponent.selectedInstruments;
    this.projectService.createProject(this.project).pipe().subscribe(
      response => {
        this.alertsService.setAlert({ type: 'success', message: this.translateService.instant("__projectCreated") });
        this.projectService.createdMessage('true');
        this.cancel(true);
        this.refresh.emit(true);
      }, error => this.alertsService.setAlert({ type: 'error', message: this.translateService.instant("__projectNotCreated") })

    );
  }

  projectIsValid() {
    return this.project.name && this.project.process_name && this.project.time && this.project.image
      && this.project.status && this.instrumentCardsComponent.selectedInstruments.length > 0;
  }

  cancel(close: boolean) {
    this.close.emit(close);
  }

  selectInstrument(instrument_selected) {
    this.project.instruments = [ instrument_selected ];
  }
}